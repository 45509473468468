import React, {useEffect, useState} from "react";
import UploadFiles from "../components/uploadFilesComponent";
import IopGeneration from "../components/IopGeneration";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Box from "@mui/material/Box";
import {Translation} from "react-i18next";
import { useForm } from "react-cool-form";

import TextField from '@mui/material/TextField';

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import moment from "moment";
import {Checkbox} from "@mui/material";

const Field = ({ label, id, error, ...rest }) => (
  <div>
    <Grid container spacing={4}>
      <Grid item m={1} md={12}>
        <TextField id={id} {...rest} label={label} />
      </Grid>
    </Grid>
    {error && <p>{error}</p>}
  </div>
);

function Generator() {
  const [getOptions, setOptions] = useState({
    signInvoice: true,
    regenerateDataOnSubmit: false
  });
  const sellerSirenGen = generate(9);
  const buyerSirenGen = generate(9);
  const sellerSiretGen = sellerSirenGen + generate(5);
  const buyerSiretGen = buyerSirenGen + generate(5);
  const randomId = Math.floor((Math.random() * 10000) + 1);

  const {form, use, reset} = useForm({
    // (Strongly advise) Provide the default values just like we use React state
    defaultValues: {
      sellerName:"Vendeur",
      buyerName:"Acheteur",
      code:"380",
      sellerSiren: sellerSirenGen,
      buyerSiren: buyerSirenGen,
      sellerSiret:sellerSiretGen,
      buyerSiret:buyerSiretGen,
      sellerElectronicAddress: sellerSirenGen + '_' + sellerSiretGen,
      buyerElectronicAddress: buyerSirenGen + '_' + buyerSiretGen,
      idInvoice:"F" + moment().format('YYYYMMDD') + "-" + randomId,
      total:Math.floor((Math.random() * 100000) + 100),
      invoiceDate:moment().format('YYYY-MM-DD'),
      tva:'20'
    },
    // The event only triggered when the form is valid
    onSubmit: (values) => {
        sendGenerateRequest(values);

        if (getOptions.regenerateDataOnSubmit) {
          const newValues = {
            ...values,
            sellerName:"Vendeur",
            buyerName:"Acheteur",
            sellerSiren: sellerSirenGen,
            buyerSiren: buyerSirenGen,
            sellerSiret: sellerSirenGen + generate(5),
            buyerSiret: buyerSirenGen + generate(5),
            sellerElectronicAddress: sellerSirenGen + '_' + sellerSiretGen,
            buyerElectronicAddress: buyerSirenGen + '_' + buyerSiretGen,
            idInvoice: "F" + moment().format('YYYYMMDD') + "-" + randomId,
            total: Math.floor((Math.random() * 100000) + 100),
          }
          reset(newValues);
        } else {
          const newValues = {
            ...values,
            idInvoice: "F" + moment().format('YYYYMMDD') + "-" + randomId,
          }
          reset(newValues);
        }
    }
    /*,
     onReset: (values) => console.log("onReset: ", values)*/
  });

  // We can enable the "errorWithTouched" option to filter the error of an un-blurred field
  // Which helps the user focus on typing without being annoyed by the error message
  const errors = use("errors", { errorWithTouched: true });

  function sendGenerateRequest(data) {
    const customEvent = new CustomEvent('event/json/generate', {detail: {...data}});
    document.dispatchEvent(customEvent);
  }

  function generate(n) {
    var add = 1, max = 12 - add;   // 12 is the min safe number Math.random() can generate without it starting to pad the end with zeros.

    if ( n > max ) {
      return generate(max) + generate(n - max);
    }

    max        = Math.pow(10, n+add);
    var min    = max/10; // Math.pow(10, n) basically
    var number = Math.floor( Math.random() * (max - min + 1) ) + min;
    return ("" + number).substring(add);
  }

  return (
    <>
      <Box sx={{mt: 1}}>
        <Tabs>
          <Box sx={{width: '60%', borderBottom: 0, marginLeft: '20%', marginRight: '20%', mt: 0}}>
            <TabList>
              <Tab>
                <Translation>{t => <span>{"  " + t('generate.fromManual')}</span>}</Translation>
              </Tab>
              <Tab>
                <Translation>{t => <span>{"  " + t('generate.fromCiiXml')}</span>}</Translation>
              </Tab>
            </TabList>
          </Box>
          <TabPanel>
            <Box sx={{width: '60%', borderBottom: 0, marginLeft: '20%', marginRight: '20%', mt: 0}}>
              <form ref={form} noValidate>
                <Grid container spacing={1}>
                  <Grid item xs={6} md={5}>
                    <Box sx={{marginLeft: '5%', marginRight: '5%', mt: 1, padding:'3px'}}>
                      <Box
                        id="category-a"
                        sx={{ fontSize: '12px', fontWeight: 'bold', mb: 1,  textTransform: 'uppercase' }}
                      >
                        Vendeur
                      </Box>
                      <Field
                        label="Nom"
                        id="sellerName"
                        name="sellerName"
                        // Support built-in validation
                        required
                        error={errors.username}
                      />
                      <Field
                        label="Siren"
                        id="sellerSiren"
                        name="sellerSiren"
                        required
                        error={errors.email}
                      />
                      <Field
                        label="Siret"
                        id="sellerSiret"
                        name="sellerSiret"
                        required
                        error={errors.email}
                      />
                      <Field
                        label="Address Electronique"
                        id="sellerElectronicAddress"
                        name="sellerElectronicAddress"
                        optional
                        error={errors.email}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Box sx={{marginLeft: '5%', marginRight: '5%', mt: 1, padding:'3px'}}>
                      <Box
                        id="category-a"
                        sx={{ fontSize: '12px', fontWeight: 'bold', mb: 1, textTransform: 'uppercase' }}
                      >
                        Acheteur
                      </Box>
                      <Field
                        label="Nom"
                        id="buyerName"
                        name="buyerName"
                        // Support built-in validation
                        required
                        error={errors.username}
                      />
                      <Field
                        label="Siren"
                        id="buyerSiren"
                        name="buyerSiren"
                        required
                        error={errors.email}
                      />
                      <Field
                        label="Siret"
                        id="buyerSiret"
                        name="buyerSiret"
                        required
                        error={errors.email}
                      />
                      <Field
                        label="Address Electronique"
                        id="buyerElectronicAddress"
                        name="buyerElectronicAddress"
                        optional
                        error={errors.email}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Box sx={{marginLeft: '5%', marginRight: '5%', mt: 1, padding:'3px'}}>
                      <Box
                        id="category-a"
                        sx={{ fontSize: '12px', fontWeight: 'bold', mb: 1,  textTransform: 'uppercase' }}
                      >
                        Options
                      </Box>
                      <div style={{ display: 'flex', alignItems: 'left' }}>
                        <Checkbox
                          id={"signInvoice"}
                          disabled={true}
                          checked={getOptions.signInvoice}
                          onChange={() => {
                            setOptions(prevOptions => ({
                              ...prevOptions,
                              signInvoice: !prevOptions.signInvoice
                            }));
                          }}
                        />
                        <label htmlFor="signInvoice">Sign Invoice</label>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'left' }}>
                        <Checkbox
                          id={"regenerateDataOnSubmit"}
                          checked={getOptions.regenerateDataOnSubmit}
                          onChange={() => {
                            setOptions(prevOptions => ({
                              ...prevOptions,
                              regenerateDataOnSubmit: !prevOptions.regenerateDataOnSubmit
                            }));
                          }}
                        />
                        <label htmlFor="regenerateDataOnSubmit">Regenerate Data</label>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Box sx={{marginLeft: '5%', marginRight: '5%', mt: 1, padding:'3px'}}>
                      <Box
                        id="category-a"
                        sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
                      >
                        Données
                      </Box>
                      <Field
                        label="Id"
                        id="idInvoice"
                        name="idInvoice"
                        // Support built-in validation
                        required
                        error={errors.username}
                      />
                      <Field
                        label="Code"
                        id="code"
                        name="code"
                        required
                        error={errors.email}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={5}>
                    <Box sx={{marginLeft: '5%', marginRight: '5%', mt: 1, padding:'3px'}}>
                      <Box
                        id="category-a"
                        sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
                      >
                        Montant
                      </Box>
                      <Field
                        label="Total HT"
                        id="total"
                        name="total"
                        // Support built-in validation
                        required
                        error={errors.username}
                      />
                      <Field
                        label="Tva (%)"
                        id="tva"
                        name="tva"
                        required
                        error={errors.email}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <input
                  type="submit"
                  style={{ display: 'none' }}
                  id="contained-button-submit"
                />
                <Box sx={{marginLeft: '5px', marginRight: '5%', mt: 1, padding:'3px'}}>
                  <label htmlFor="contained-button-submit">
                    <Button id="btn-submit-one" variant="contained" color="primary" component="span">
                      <Translation>{t => <span>{t('generate.btnGenerate')}</span>}</Translation>
                    </Button>
                  </label>
                </Box>
              </form>
            </Box>
          </TabPanel>
          <TabPanel>
            <Translation>{t => <UploadFiles config={{eventName:"generate", acceptExt:".xml", btnName:t('generate.btnGenerate')}}/>}</Translation>
          </TabPanel>
          {/*<TabPanel>
            <h2>Any content 2</h2>
          </TabPanel>*/}
        </Tabs>
      </Box>
      <br/><br/>
      <IopGeneration/>
    </>
  );
}

export default Generator;