import {getBezierPath, EdgeLabelRenderer, BaseEdge, MarkerType} from 'reactflow';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArticleIcon from '@mui/icons-material/Article';

import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import Box from '@mui/material/Box';
import useStateRef from '../../common/useStateRef';
import moment from 'moment/moment';
import {subscribe, unsubscribe} from '../../controller/event';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {github} from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import vkbeautify from 'vkbeautify';

moment.locale('fr');

const CustomPpfEdge = ({
                      id,
                      sourceX,
                      sourceY,
                      targetX,
                      targetY,
                      sourcePosition,
                      targetPosition,
                      markerEnd,
                      data,
                    }) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const STATUS_COLOR = {
    FAILURE: '#FF0072',
    SUCCESS: '#ffcc00'
  };

  const STATUS_RECEIVED_CSS = {
    p: 2,
    border: '2px dashed red',
    borderRadius: '60%',
    transition: 'all 0.5s ease-in-out'
  };
  const STATUS_CSS = {
    backgroundColor: '#f5f5f6',
    borderRadius: '60%',
    transition: 'all 0.5s ease-in-out'
  };

  const [statusColor, setStatusColor] = React.useState('');
  const [expanded, setExpanded] = React.useState(false);
  const [getListItemsState, setListItemsState] = useStateRef([]);
  const [expandedFirst, setExpandedFirst] = React.useState('none');
  const [getStyle, setStyle] = React.useState(STATUS_CSS);

  const handleFirstLevelExpand = (panel) => (event, isExpanded) => {
    setExpandedFirst(isExpanded ? panel : false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [value, setValue] = React.useState('1');

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reset = () => {
    setListItemsState([]);
    setStatusColor('');
  };

  const handlePpfStatusReceived = (data) => {
    setStyle(STATUS_RECEIVED_CSS);
    setTimeout(() => {
      setStyle(STATUS_CSS)
    }, 500);

    if (data?.detail) {
      if (statusColor === '') {
        setStatusColor(STATUS_COLOR[data.detail?.statusSuccess ? 'SUCCESS' : 'FAILURE']);

      } else if (statusColor === STATUS_COLOR.SUCCESS && !data.detail?.statusSuccess) {
        setStatusColor(STATUS_COLOR.FAILURE);
      }
      setListItemsState([{...data?.detail, date: moment().format('YYYY-MM-DD HH:mm:ss')}, ...getListItemsState()]);
    }
  };

  React.useEffect(() => {
    subscribe('@event/PPF_STATUS_RECEIVED', handlePpfStatusReceived);
    subscribe('@event/RESET_ALL', reset);

    return () => {
      unsubscribe('@event/PPF_STATUS_RECEIVED', handlePpfStatusReceived);
      unsubscribe('@event/RESET_ALL', reset);
    }
  }, []);

  const renderContent = (item) => {

    return <Box>
      <AccordionDetails>
        <SyntaxHighlighter language="xml" style={github}>
          {item.xmlLc}
        </SyntaxHighlighter>
      </AccordionDetails>
    </Box>
  }

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} style={{
        strokeWidth: 2,
        stroke: statusColor
      }}/>
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            backgroundColor: 'transparent',
            borderRadius: '50%',
            border: 20,
            fontSize: '10px',
            padding: 0,
            fontWeight: 100,
            pointerEvents: 'all'
          }}
        >
          <IconButton
            edge="start"
            color="black"
            onClick={handleClickOpen}
            aria-label="open"
          >
            <ArticleIcon sx={getStyle}/>
          </IconButton>
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>Liste des statuts émit vers le portail public de facturation (PPF)</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Trier par date
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {getListItemsState() &&
                  getListItemsState().map((item, i) => (
                    <Accordion key={item.id} expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{width: '25%', flexShrink: 0}}>
                          {item.date}
                        </Typography>
                        <Typography sx={{width: '35%', flexShrink: 0}}>
                          {item?.id}
                        </Typography>
                        <Typography sx={{width: '20%', flexShrink: 0}}>
                          {item?.code}
                        </Typography>
                        <Typography sx={{width: '20%', flexShrink: 0}}>
                          {item?.description}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {renderContent(item)}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomPpfEdge;
