import React, {Component} from "react";
import UploadService from "../services/uploadFilesService";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import { Translation } from 'react-i18next';

export default class UploadFiles extends Component {
  constructor(props) {
    super(props);
    this.selectFiles = this.selectFiles.bind(this);
    this.upload = this.upload.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);

    //custom parameters
    this.eventName = props?.config?.eventName;
    this.acceptExt = props?.config?.acceptExt;
    this.btnName = props?.config?.btnName || "Analyse";

    this.state = {
      selectedFiles: undefined,
      progressInfos: [],
      message: [],

      fileInfos: [],
    };
  }

  componentDidMount() {
    /*UploadService.getFiles().then((response) => {
      this.setState({
        fileInfos: response.data,
      });
    });*/
  }

  selectFiles(event) {
    this.setState({
      progressInfos: [],
      selectedFiles: event.target.files,
    });
  }

  upload(idx, file) {
    let _progressInfos = [...this.state.progressInfos];

    UploadService.upload(file, (event) => {
      _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
      this.setState({
        _progressInfos,
      });
    })
      .then((response) => {
        const customEvent = new CustomEvent('event/file-uploaded/' + this.eventName, {detail: {sourceFile: file.name, ...response.data}});
        document.dispatchEvent(customEvent);

        let done = true;
        for (let i = 0; i < this.state.progressInfos.length; i++) {
          if (this.state.progressInfos[i]?.percentage !== 100) {
            done = false;
          }
        }

        if (done) {
          new Promise(r => setTimeout(r, 1000)).then(() => {
            this.setState({
              progressInfos: []
            });
          });
        }
      })
      .catch(() => {
        _progressInfos[idx].percentage = 0;
        this.setState((prev) => {
          let nextMessage = [...prev.message, "Could not upload the file: " + file.name];
          return {
            progressInfos: _progressInfos,
            message: nextMessage
          };
        });
      });
  }

  uploadFiles() {
    const selectedFiles = this.state.selectedFiles;

    let _progressInfos = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      _progressInfos.push({percentage: 0, fileName: selectedFiles[i].name});
    }

    this.setState(
      {
        progressInfos: _progressInfos,
        message: [],
      },
      () => {
        for (let i = 0; i < selectedFiles.length; i++) {
          this.upload(i, selectedFiles[i]);
        }
      }

    );
  }

  showExecButton() {
    return   <div className="col-4">
      <button
        className="btn btn-success btn-sm"
        style={{ display: 'none' }}
        onClick={this.uploadFiles}
        id="contained-button-exec"
      />
      <label htmlFor="contained-button-exec">
        <Button variant="contained" color="primary" component="span">
          {this.btnName}
        </Button>
      </label>
    </div>
  }

  render() {
    const {selectedFiles, progressInfos, message, fileInfos} = this.state;

    return (
      <Box sx={{width: '60%', borderBottom: 1, marginLeft: '20%', marginRight: '20%', mt: 5}}>
        <div>
          {progressInfos &&
            progressInfos.map((progressInfo, index) => (
              <div className="mb-2" key={index}>
                <span>{progressInfo.fileName}</span>
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-info"
                    role="progressbar"
                    aria-valuenow={progressInfo.percentage}
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{width: progressInfo.percentage + "%"}}
                  >
                    {progressInfo.percentage}%
                  </div>
                </div>
              </div>
            ))}

          <div className="row my-3">
            <div className="col-8">
              <input
                type="file"
                multiple
                onChange={this.selectFiles}
                accept={this.acceptExt}
                style={{ display: 'none' }}
                id="contained-button-file"
              />
              <label htmlFor="contained-button-file">
                <Button variant="contained" color="primary" component="span">
                  <Translation>{t => <span>{t('upload.browse')}</span>}</Translation>
                </Button>
              </label>{(!selectedFiles || selectedFiles.length === 0) ?
                <Translation>{t => <span>{"  " + t('upload.noSelection')}</span>}</Translation> :
                <Translation>{t => <span>{"  " + selectedFiles.length + " " + t('upload.filesSelected')}</span>}</Translation>}
            </div>
            {(selectedFiles && selectedFiles.length !== 0) ? this.showExecButton() : null}
          </div>

          {message.length > 0 && (
            <div className="alert alert-secondary" role="alert">
              <ul>
                {message.map((item, i) => {
                  return <li key={i}>{item}</li>;
                })}
              </ul>
            </div>
          )}
        </div>
      </Box>
    );
  }
}