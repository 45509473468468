import React from "react";
import UploadFiles from "../components/uploadFilesComponent";
import IopValidation from "../components/IopValidation";

function Validator() {
  return (
    <div>
      <UploadFiles config={{eventName:"validate", acceptExt:".pdf,.xml"}}/>
      <br/><br/>
      <IopValidation/>
    </div>
  );
}

export default Validator;