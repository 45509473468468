import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";

import vkbeautify from 'vkbeautify';
import moment from 'moment';

import {validateInvoice} from '../services/HttpService';
import IopValidationDetails from "./IopValidationDetails";
import useStateRef from "../common/useStateRef";
import {toast} from 'react-toastify';

moment.locale('fr');

export default function IopValidation() {
    const [expanded, setExpanded] = React.useState("none");
    const [getListValidationResult, setListValidationResult] = useStateRef([]);

    const handleNewFile = (event) => {
        console.log("New file to analyse uploaded (file : " + event.detail.sourceFile + ")");
        executeValidation(event.detail);
    }

    const executeValidation = async (sourceFileData) => {
        const baseData = {
            id : sourceFileData.fileid,
            running: true,
            label: sourceFileData.sourceFile,
            time: moment().format('DD/MM/YYYY HH:mm:ss'),
        };

        //add list entry at running state
        setListValidationResult([baseData, ...getListValidationResult()]);

        console.log("Invoice validation request sent (file : " + sourceFileData.sourceFile + ", id : " + baseData.id + ")");
        try {
            const result = await validateInvoice(baseData.id);
            console.log('Invoice validation result received (id : ' + baseData.id + ")", result);

            const items = getListValidationResult().map(item => {
                if (item.id === baseData.id) {
                    return {
                        id : baseData.id,
                        running: false,
                        label: baseData.label,
                        ext: (sourceFileData.sourceFile.substring(sourceFileData.sourceFile.lastIndexOf('.')+1
                          , sourceFileData.sourceFile.length) || sourceFileData.sourceFile),
                        time: moment().format('DD/MM/YYYY HH:mm:ss'),
                        ...result,
                        extractedData: ((result?.invoiceValidation?.invoiceXmlData)
                          ? vkbeautify.xml(result?.invoiceValidation?.invoiceXmlData)
                          : vkbeautify.json(result?.cause)),
                    };
                } else {
                    return item;
                }
            });
            setListValidationResult(items);
        } catch (error) {
            toast.error('Failure when trying to validate invoice from json!');
        }
    }

    React.useEffect(() => {
        document.addEventListener('event/file-uploaded/validate', handleNewFile);
        return () => {
            document.removeEventListener('event/file-uploaded/validate', handleNewFile);
        };
    }, []);

    const handleFirstLevelExpand = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    function renderResult(item) {
        if (item.failure) {
            return <Typography sx={{width: '10%', fontSize: '18px', fontWeight: 'bold', flexShrink: 0, color: 'red'}}>
                Server Failure
            </Typography>;
        }
        if (item.running) {
            return <Typography sx={{width: '10%', fontSize: '18px', fontWeight: 'bold', flexShrink: 0, color: 'blue'}}>
                Running
            </Typography>;
        } else {
            if (item.valid) {
                return <Typography
                    sx={{width: '10%', fontSize: '18px', fontWeight: 'bold', flexShrink: 0, color: 'green'}}>
                    Valid
                </Typography>;
            } else {
                return <Typography
                    sx={{width: '10%', fontSize: '18px', fontWeight: 'bold', flexShrink: 0, color: 'red'}}>
                    Invalid
                </Typography>;
            }
        }
    }

    return (
        <Box sx={{width: '80%', borderBottom: 0, marginLeft: '10%', marginRight: '10%', mb: 10}}>
                {getListValidationResult() &&
                    getListValidationResult().map((result, i) => (
                        <Accordion expanded={!result.running && (expanded === 'panel' + i)} onChange={handleFirstLevelExpand('panel' + i)}>
                            <AccordionSummary
                                expandIcon={result.running ? null : <ExpandMoreIcon/>}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                {renderResult(result)}
                                <Typography sx={{width: '60%', flexShrink: 0}}>
                                    {result.label}
                                </Typography>
                                <Typography sx={{width: '20%', flexShrink: 0}}>
                                    {result.time}
                                </Typography>
                            </AccordionSummary>
                            {result.running || result.failure || (expanded !== 'panel' + i) ? null : <IopValidationDetails item={result}/>}
                        </Accordion>
                    ))}
        </Box>
    );
}