'use strict'
const moment = require("moment");

/**
 * Computes the duration between two dates and returns the result in the format 'hh:mm:ss'.
 * @param {Date} startDate The start date in ISO 8601 format.
 * @param {Date} endDate The end date in ISO 8601 format.
 * @returns {string} The duration between the start and end dates in 'hh:mm:ss' format.
 */
function computeDurationTimeCode(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  // Compute duration in milliseconds
  const duration = moment.duration(endMoment.diff(startMoment));

  // Format duration as hh:mm:ss
  return moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
}

/**
 * Computes the duration between two dates and returns the result in the format 'hh:mm:ss'.
 * @param {Date} startDate The start date in ISO 8601 format.
 * @param {Date} endDate The end date in ISO 8601 format.
 * @returns {moment.Duration} The duration between the start and end dates in milliseconds.
 */
function computeDurationMs(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  // Compute duration in milliseconds
  return moment.duration(endMoment.diff(startMoment));
}

/**
 Generates a random string of characters.
 @param {number} [randomNumber=14] - The length of the random string to generate.
 @returns {string} A random string of characters.
 */
function randomChar(randomNumber = 14) {
  let chars = 'abcdefghijklmnopqrstuABCDEFGHIJKLMNOPQRSTU';
  let randomString = '';

  for (let i = 0; i < randomNumber; i++) {
    let randomIndex = Math.floor(Math.random() * chars.length);
    randomString += chars[randomIndex];
  }

  return randomString;
}

/**
 * Generates a random integer between a minimum and maximum value, excluding specified numbers.
 *
 * @param {number} min - The minimum value of the random number (inclusive).
 * @param {number} max - The maximum value of the random number (inclusive).
 * @param {number[]} excludedNumbers - An array of numbers to be excluded from the generated random number.
 * @returns {number} A random integer between the specified minimum and maximum values, excluding the specified numbers.
 */
function randomInteger(min, max, excludedNumbers) {
  let randomNum;
  do {
    randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
  } while (excludedNumbers.includes(randomNum));

  return randomNum;
}

/**
 *
 * @param {object} buyer
 * @param {object} seller
 * @return {{buyerSiren: (string|string|*), sellerSiren: (string|string|*), total: number, code: string, idInvoice: string, sellerName, sellerSiret: (string|string|*), buyerSiret: (string|string|*), buyerName, invoiceDate: *, tva: string}}
 */
function createInvoiceToGenerateJson(buyer, seller) {
  return {
    'sellerName': seller.name,
    'buyerName': buyer.name,
    'code': '380',
    'sellerSiren': seller.entity.siren,
    'buyerSiren': buyer.entity.siren,
    'sellerSiret': seller.entity.siret,
    'buyerSiret': buyer.entity.siret,
    'idInvoice': 'F-' + randomChar(),
    'total': randomInteger(150, 150000, []),
    'invoiceDate': moment().format('YYYY-MM-DD'),
    'tva': '20'
  };
}

/**
 * Calculates the VAT number (TVA) based on the given SIREN number.
 *
 * @param {string} siren - The SIREN number (must be a 9-digit string).
 * @returns {string} - The VAT number in the format "FRXX123456789".
 * @throws {Error} - Throws an error if the SIREN number is not a valid 9-digit string.
 */
function computeVATNumber(siren) {
  // Validate if the SIREN is a 9-digit number
  if (!/^\d{9}$/.test(siren)) {
    throw new Error('The SIREN must be a 9-digit number.');
  }

  // Convert the SIREN to an integer for calculation
  const sirenNumber = parseInt(siren, 10);

  // Calculate the control key
  const key = (12 + 3 * (sirenNumber % 97)) % 97;

  // Format the key to ensure it is two digits
  const keyString = key < 10 ? '0' + key : key.toString();

  // Construct the VAT number
  return `FR${keyString}${siren}`;
}

module.exports = {
  computeDurationTimeCode,
  computeDurationMs,
  randomChar,
  randomInteger,
  createInvoiceToGenerateJson,
  computeVATNumber
}