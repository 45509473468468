import axios from 'axios';
import UserService from './UserService';

const config = require('../config/');

const HttpMethods = {
    GET: 'GET',
    POST: 'POST',
    DELETE: 'DELETE',
};

const _axios = axios.create({
    baseURL: window.location.protocol + '//' + window.location.host,
    timeout: 10000,
    params: {} // do not remove this, its added to add params later in the config
});

const configure = () => {
    _axios.interceptors.request.use((config) => {
        if (UserService.isLoggedIn()) {
            const cb = () => {
                config.headers.Authorization = `Bearer ${UserService.getToken()}`;
                return Promise.resolve(config);
            };
            return UserService.updateToken(cb);
        }
    });
};

export function getAxiosClient() {
    return _axios;
}

export async function validateInvoice(id) {
    //const response = await fetch(window.location.protocol + '//' + window.location.host + endpoints.validate
    //        + new Buffer(data.path).toString("base64"), {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.validate + id, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserService.getToken()},
    })
    return returnResponse(response);
}

export async function generateInvoice(id) {
    //const response = await fetch(window.location.protocol + '//' + window.location.host + endpoints.validate
    //        + new Buffer(data.path).toString("base64"), {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.generate + id, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserService.getToken()},
    })
    return returnResponse(response);
}

export async function generateInvoiceFromJson(data) {
    //const response = await fetch(window.location.protocol + '//' + window.location.host + endpoints.validate
    //        + new Buffer(data.path).toString("base64"), {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.generateFromJson, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserService.getToken()},
        body: JSON.stringify(data)
    })
    return returnResponse(response);
}

export async function generateMultipleInvoiceFromJson(data, number) {
    //const response = await fetch(window.location.protocol + '//' + window.location.host + endpoints.validate
    //        + new Buffer(data.path).toString("base64"), {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.generateMultipleFromJson + '/' + number, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserService.getToken()},
        body: JSON.stringify(data)
    })
    return returnResponse(response);
}

export async function registerAndGetUser() {
    //const response = await fetch(window.location.protocol + '//' + window.location.host + endpoints.validate
    //        + new Buffer(data.path).toString("base64"), {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.registerUser, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserService.getToken()}
    });
    return returnResponse(response);
}

export async function createSandbox() {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.createSandbox, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserService.getToken()}
    });
    return returnResponse(response);
}

export async function removeSandbox() {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.removeSandbox, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + UserService.getToken()}
    });
    return returnResponse(response);
}

/**
 * Send invoice from labs to pdp using generated file id.
 *
 * @param id
 * @returns {Promise<*|undefined>}
 */
export async function emitInvoiceById(id) {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.pdp.emit + id, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }
    });
    return returnResponse(response);
}

/**
 *
 * @param offset
 * @param limit default 100
 * @param {string} randomChar
 * @returns {Promise<*|undefined>}
 */
export async function getRandomDirectoryEntry(offset, limit, randomChar) {
    let query = '?offset=' + offset + '&limit=' + limit + '&value=' + randomChar;
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.pdp.directory.get + query, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }
    });
    return (await returnResponse(response))[0];
}

/**
 *
 * @param {string} participantName
 * @returns {Promise<*|undefined>}
 */
export async function getParticipantByName(participantName) {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.pdp.participant.get.replace(':participantName', participantName), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }
    });

    if (!response || response.status >= 500) {
        throw Error(response.message);
    } else if (response.status === 404) {
        return null;
    } else {
        return response.json();
    }
}

/**
 *
 * @param {Object} participant
 * @returns {Promise<*|undefined>}
 */
export async function addParticipant(participant) {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.pdp.participant.create, {
        method: 'POST',
        body: JSON.stringify(participant),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }
    });

    if (!response || response.status >= 500) {
        throw Error(response.message);
    } else {
        return response.json();
    }
}

/**
 *
 * @param {number} participantId
 * @param {Object} networkData
 * @returns {Promise<*|undefined>}
 */
export async function attachParticipantToNetwork(participantId, networkData) {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.pdp.participant.addInNetwork.replace(':participantId', participantId), {
        method: 'POST',
        body: JSON.stringify(networkData),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }
    });

    if (!response || response.status >= 500) {
        throw Error(response.message);
    } else {
        return response.json();
    }
}


export async function getDirectoryCallGeneratedCode(offset, limit) {
    let query = '?offset=' + offset + '&limit=' + limit;
    const response = await fetch(window.location.protocol + '//' + window.location.host + '/v1/generator/code/directory' + query, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }
    });
    return returnResponse(response);
}

export async function getEmitInvoiceCallGeneratedCode(invoiceId) {
    const response = await fetch(window.location.protocol + '//' + window.location.host + '/v1/generator/code/emit/invoice?invoiceId=' + invoiceId, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }
    });
    return returnResponse(response);
}

export async function getEmitStatusCallGeneratedCode(invoiceId, code, message, amount = null, currency = null) {
    let additionalQuery = undefined;
    if (amount && currency) {
        additionalQuery = '&amount=' + amount + '&currency=' + currency;
    }

    const response = await fetch(window.location.protocol + '//' + window.location.host + '/v1/generator/code/emit/status?code=' + code + '&message=' + message + "&invoiceId=" + invoiceId + (additionalQuery ? additionalQuery : ''), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        }
    });
    return returnResponse(response);
}

/**
 * @param invoiceId
 * @returns {Promise<any>}
 */
export async function sendRefusedInvoice(invoiceId) {
    await sendStatus(invoiceId, "REFUSED", "Facture refusée par le client");
}

/**
 * @param invoiceId
 * @returns {Promise<any>}
 */
export async function sendApprovedInvoice(invoiceId) {
    await sendStatus(invoiceId, "APPROVED", "La facture est en cours de traitement par le destinataire");
}

/**
 * @param invoiceId
 * @return {Promise<void>}
 */
export async function sendAcceptInvoice(invoiceId) {
    await sendStatus(invoiceId, "IN_HAND", "Facture Prise en charge par le client");
}

/**
 *
 * @param invoiceId
 * @param currency
 * @param amount
 * @returns {Promise<void>}
 */
export async function sendPayedInvoice(invoiceId, currency, amount) {
    await sendStatusRaw(invoiceId, {
        code: "PAYMENT_SENT",
        message: "La facture est payé",
        payment: {
            currency: currency,
            amount: amount
        }
    });
}

/**
 *
 * @param invoiceId
 * @param currency
 * @param amount
 * @returns {Promise<void>}
 */
export async function sendPaymentReceived(invoiceId, currency = null, amount = null) {
    await sendStatusRaw(invoiceId, {
        code: "PAYMENT_RECEIVED",
        message: "La facture est encaissé",
        payment: {
            currency: currency,
            amount: amount
        }
    });
}

async function sendStatus(invoiceId, code, message) {
    await sendStatusRaw(invoiceId, {
        code: code,
        message: message
    });
}

async function sendStatusRaw(invoiceId, data) {
    const response = await fetch(window.location.protocol + '//' + window.location.host + config.endpoints.pdp.status.replace(':invoiceId', invoiceId), {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + UserService.getToken()
        },
        body: JSON.stringify(data)
    });
    return returnResponse(response);
}


/**
 * [PRIVATE] ----------
 * @param response
 * @returns {Promise<*>}
 */
const returnResponse = async (response) => {
    if (!response || response.status >= 400) {
        throw Error(response.message);
    } else if (response.status === 204) {
        return response;
    } else {
        return await response.json();
    }
}

const HttpService = {
    HttpMethods,
    configure,
    getAxiosClient,
    sendRefusedInvoice,
    sendApprovedInvoice,
    generateInvoice,
    sendAcceptInvoice,
    validateInvoice,
    registerAndGetUser,
    createSandbox,
    removeSandbox,
    emitInvoiceById,
    generateInvoiceFromJson,
    getRandomDirectoryEntry,
    getParticipantByName,
    addParticipant,
    attachParticipantToNetwork,
    getDirectoryCallGeneratedCode,
    getEmitInvoiceCallGeneratedCode,
    getEmitStatusCallGeneratedCode,
    sendPayedInvoice,
    sendPaymentReceived
};

export default HttpService;